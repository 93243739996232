.page-help {
    width: 100%;

    .page-title {
        font-size: 3rem;
        margin-top: 2rem;
    }
    .page-subtitle {
        font-size: 1.8rem;
        font-weight: normal;
        font-style: italic;
    }

    .steps-title {
        font-size: 2.5rem;
        line-height: 3.2rem;
    }

    .steps-block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 1.8rem;

        .steps-item {
            display: flex;
            position: relative;
            margin-bottom: 1rem;

            .steps-item-text-container {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.5rem 3.2rem 1rem 3rem;

                .steps-item-text {
                    display: flex;
                    flex-direction: column;
                    text-align: left;

                    .steps-item-header,
                    .steps-item-info {
                        font-style: italic;
                    }

                    .steps-item-header {
                        font-size: 2rem;
                        font-weight: bold;
                        text-transform: uppercase;
                        line-height: 2.5rem;
                    }

                    .steps-item-info {
                        font-size: 2.5rem;
                        line-height: 2.7rem;
                        max-width: 16rem;
                    }
                }
            }

            .steps-image {
                width: 3.3rem;
            }

            &:before,
            &:after {
                content: "";
                display: block;
                position: absolute;
                background: url(../../assets/img/bracket.png);
                background-size: contain;
                background-repeat: no-repeat;
                width: 100%;
                height: 100%;
            }

            &:after {
                transform: rotate(180deg);
                right: 0;
            }
        }
    }

    .button1 {
        margin-top: 2rem;
    }

}

body.lang-es {
    .steps-item-info{
        font-size: 2rem !important;
        line-height: 2.2rem !important;
    }
}

.page-form {
    .page-title {
        margin-top: 4.3rem;
        font-size: 3.2rem;
        line-height: 3rem;
        letter-spacing: -0.13rem;
    }

    form {
        width: 100%;
    }

    .form-input {
        width: 100%;
        margin: 3rem 0;
        padding: 0.37rem 1rem;
        color: #737373;
        font-size: 1.5rem;
        border-radius: 0.6rem;
        background: white;
        border: none;
    }

    .check-header {
        font-size: 1.2rem;
        text-transform: uppercase;
        letter-spacing: 0.27rem;
        line-height: 1.3rem;
        &.text-glow {
            text-shadow: 0px 0px 5px rgb(255 255 255 / 0.5), 0 0px 5px rgb(255 255 255 / 0.5);
        }
    }

    .check-group {
        max-width: 95vw;
        margin-bottom: 2rem;
        .checkbox {
            margin: 1.6rem 0 1.9rem 0;
        }
    }

    button {
        font-size: 2.2rem;
        padding: 0.5rem;
    }

    &.page-form-email {
        .page-title {
            padding: 0 1.1rem;
        }
    }

    .privacy {
        margin: 2rem 0 15px 0;
        a {
            text-decoration: underline;
            font-style: italic;
            font-size: 1.2rem;
        }
    }
}

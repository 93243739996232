.page-select {
    font-size: 2rem;
    font-style: italic;
    line-height: 1.5;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.page-five-players {
    font-size: 3.5rem;
    font-weight: bold;
    font-style: italic;
    line-height: 1;
    letter-spacing: 1px;
    margin-bottom: 1rem;
    text-shadow: 0 0 2px #fff;
    text-transform: uppercase;
}

.button-mar {
    margin-top: 10px;
    text-transform: uppercase;
}

.players-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .player {
        width: 26%;
        margin-top: 0.7rem;
        margin-right: 1rem;
        .player-img-wrapper {
            position: relative;
            justify-content: center;
            .player-img {
                height: 7rem;
                z-index: 1;
            }
            &:before,
            &:after {
                content: '';
                position: absolute;
                background-repeat: no-repeat;
                background-size: contain;
                transition: width 0.3s, height 0.3s;
            }
            &:before {
                width: 4.45rem;
                height: 4.45rem;
                border: 0.15rem solid white;
                border-radius: 50%;
            }
            &:after {
                background-image: url('../../assets/img/star.png');
                width: 0;
                height: 0;
            }
        }
        .player-first-name,
        .player-last-name {
            font-size: 1rem;
            font-style: italic;
        }
        .player-last-name {
            font-weight: bold;
        }
        &.player-selected {
            .player-img-wrapper {
                &:before {
                    width: 0;
                    height: 0;
                }
                &:after {
                    width: 7rem;
                    height: 7rem;
                }
            }
        }
        &:nth-child(3),
        &:last-child {
            margin-right: 0;
        }
    }
}

@import './styles/fonts.scss';
@import './styles/constants.scss';

:root {
    --page-opacity: 0;
    --font-size: 1.6vh;
}

html,
body {
    margin: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, rgba(11, 143, 207, 1) 0%, rgb(46, 175, 228) 100%);
    background-attachment: fixed;
    min-height: calc(var(--vh, 1vh) * 100);
    touch-action: manipulation;
    overflow: hidden;
}

html {
    font-size: calc(var(--font-size, 1vh) * 1.6);
    font-family: AATAleckCd;
    transition: font-size 0.2s;
}

body {
    overflow: hidden;
}

#root {
    height: 100%;
    max-width: 40rem;
    width: 100%;
    opacity: var(--page-opacity);
    transition: opacity 0.5s;
    padding: 5rem 2.6rem 1.9rem 2.6rem;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/img/stars.png');
        background-repeat: no-repeat;
        opacity: 0.5;
        pointer-events: none;
    }
}

main {
    height: 100%;
    width: 100%;
    text-align: center;
}

* {
    touch-action: manipulation;
    box-sizing: border-box;
    color: white;
    font-family: AATAleckCd;
    outline: none;
}

:not(input) {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

.page-title {
    font-size: 4.5rem;
    font-weight: bold;
    font-style: italic;
    text-transform: uppercase;
    margin-top: 2.5rem;
}

.button1,
.button2,
.button3 {
    background-image: url(../assets/img/btnBack1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    border: 0px;
    border-radius: 0.6rem;
    width: 100%;
    padding: 0.4rem;
    font-size: 2rem;
    font-weight: bold;
    font-style: italic;
    text-transform: uppercase;
    &:active {
        background-color: white;
        color: #0c90d0;
    }
}

.button2 {
    background-image: url(../assets/img/btnBack2.png);
}

.button3 {
    background-image: url(../assets/img/btnBack3.png);
}

button {
    background: transparent;
}

button:disabled {
    opacity: 0.5;
}

.link-button {
    font-style: italic;
    font-size: 0.85rem;
    margin-bottom: 15px;
}

.mode-switch {
    width: auto;
    padding: 0 0.15rem;
    font-size: 1rem;
    font-style: italic;
    border: none;
    border-bottom: solid white;
    border-radius: 0;
    position: absolute;
    left: 1rem;
    top: 1rem;
}

.text-glow {
    text-shadow: 0px 0px 10px rgb(255 255 255 / 0.25), 0 0px 10px rgb(255 255 255 / 0.25);
}

.align-cc {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (orientation: landscape) {
    html {
        font-size: 1.3vw;
    }
    body {
        overflow: auto;
    }
    #root {
        height: auto;
    }
    .footer {
        margin-top: 3rem;
    }
}
.checkbox {
    display: flex;
    position: relative;

    input[type='checkbox'] {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .checkbox-label {
        font-size: 1.3rem;
        text-align: left;
        font-weight: 300;
        line-height: 1.3rem;
        display: block;
        margin-left: 0.6rem;
        flex: 1;
    }

    &:before {
        content: '';
        width: 1.65rem;
        height: 1.65rem;
        display: block;
        background: white;
        border-radius: 0.2rem;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0.2rem;
        top: 0.15rem;
        width: 1.4rem;
        height: 1.4rem;
        background: url('../../assets/img/check.svg');
        background-repeat: no-repeat;
        transform: scale(0);
        transition: transform 0.1s;
    }

    &.checkbox-checked {
        &:after {
            transform: scale(1);
        }
    }

    // Add the new styles here
    &.checkbox-disabled {
        opacity: 0.5 !important;
        cursor: not-allowed !important;
    }
    
    &.checkbox-disabled .checkbox-label {
        color: #ccc !important;
    }
}

.page-timeout {
    width: 100%;
    max-width: 25rem;
    
    .page-timeout-message {
        font-weight: 300;
        font-style: italic;
        margin: 9.6rem 0 5.6rem 0;
        font-size: 3rem;
        line-height: 3.7rem;
        letter-spacing: -0.07rem;
        max-width: 22rem;
    }
}

.page-qr {
    width: 100%;
}

.page-title-qr {
    font-size: 4.2rem;
    font-weight: bold;
    font-style: italic;
    line-height: 1;
    letter-spacing: 1px;
    text-shadow: 0 0 2px #fff;
    margin-top: 2rem;
    text-transform: uppercase;
}

.page-sub-title-qr {
    font-size: 1.5rem;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 2rem;
    text-shadow: 0 0 4px #ffffff80;
    text-transform: uppercase;
}

.button-margin {
    margin-top: 20px;
}

.qr-code {
    canvas {
        height: auto;
        width: 90%;
    }
}
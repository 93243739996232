.header-container {
    display: flex;
    align-items: center;

    .header-data-container {
        width: 100%;
        text-decoration: none;

        .logo {
            position: relative;
            height: 5rem;
        }
    }
}

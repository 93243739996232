/* Popup container */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Ensure it stays on top */
}

/* Popup wrapper */
.popup-wrapper {
  width: 285px; /* Fixed width */
  height: 315px; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Popup content */
.popup-content {
  width: 100%; /* Fill the width of the wrapper */
  height: auto; /* Fill the height of the wrapper */
  background-size: cover; /* Cover the entire content area */
  background: url(../../assets/img/popupBox.png) no-repeat center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  font-size: 1.5rem;
  font-style: italic;
  text-transform: uppercase;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative; /* For anchoring the continue button */
  padding-top: 10px;
}

/* Popup buttons container */
.popup-buttons {
  display: flex;
  margin-top: 7.5px;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-bottom: 20px; /* Add padding at the bottom to create space for the button */
  position: relative; /* Make the container relatively positioned */
  padding-top: 10px;
}

/* Styling the radio buttons */
.popup-buttons input[type="radio"] {
  transform: scale(1.5); /* Scale the radio button size */
  margin-right: 10px; /* Space between the radio button and label */
  appearance: none; /* Remove default styling */
  border: 2px solid white; /* Set the rim color to white */
  border-radius: 50%; /* Make the button circular */
  background-color: rgb(255, 255, 255); /* Make the inside of the button white */
  width: 20px;  /* Set the width of the radio button */
  height: 20px; /* Set the height of the radio button */
  position: relative; /* Position relative for the custom checked state */
  vertical-align: middle; /* Ensure vertical alignment with text */
}

/* Custom checked state for the radio button */
.popup-buttons input[type="radio"]:checked::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: rgb(0, 87, 184); /* Inside dot color */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Label styling to ensure alignment */
.popup-buttons label {
  display: flex;
  align-items: center; /* Vertically centers the radio button and text */
}

.popup-content h2 {
  margin-bottom: 20px; /* Adjust this value as needed */
}

/* Continue button anchored to the bottom */
.popup-buttons .continue-button {
  position: absolute;
  bottom: 0; /* Anchor to the bottom of .popup-buttons */
  left: 50%;
  transform: translateX(-50%);
  width: 50% !important;
  height: auto !important;
  padding: 5px 15px !important;
  font-size: 1rem !important;
}

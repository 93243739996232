.thanks-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 5rem;
}

.thanks-header {
    font-style: italic;
    font-weight: bold;
    font-size: 2.6rem;
    line-height: 1.1em;
    text-transform: uppercase;
}

.thanks-details {
    font-size: 2.2rem;
    line-height: 2rem;
    margin-top: 20px;
    font-weight: 300;
}

.final-image {
    width: 180px;
    height: auto;
    box-sizing: content-box;
    margin-top: 50px;
    border: solid 0.9rem white;
    box-shadow: 2px 2px 10px rgba(0,0,0, 0.22);
}
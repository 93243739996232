.lookAtScreen-page-container {
    height: 100%;
}

.lookAtScreen-text {
    font-style: italic;
    font-weight: 900;
    font-size: 6.8rem;
    line-height: 1.09em;
    text-transform: uppercase;
    margin-top: 3.7rem;
    letter-spacing: -4px;
}

body.lang-es {
    .lookAtScreen-text {
        letter-spacing: -2px !important;
    }
}

.button-margin {
    margin-top: 30px;
}

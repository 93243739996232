.welcome-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    .page-title {
        font-size: 5rem;
        margin-top: 2rem;
    }
}

.welcome-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.welcome-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 9rem;
    margin-top: 6vh;
}

.welcome-text {
    font-style: italic;
    font-size: 2.8rem;
    padding-top: 4vh;
    margin: 0px 2.2rem;
}

.welcome-link-button {
    border: solid 0px transparent;
    text-decoration: underline;
    font-style: italic;
    font-size: 1.2rem;
    margin: 2rem 0 15px 0;
}